<div class="policy-wrapper">
  <h1>Politique de confidentialité du site NooS Global</h1>

  <h2>Article 1 : Préambule</h2>
  <p>
    Cette politique de confidentialité s'applique au site : NooS Global.<br>
    La présente politique de confidentialité a pour but d'exposer aux utilisateurs du site :
  </p>
  <ul>
    <li>
      La manière dont sont collectées et traitées leurs données à caractère personnel. Doivent être considérées
      comme données personnelles toutes les données étant susceptibles d'identifier un utilisateur. Il s'agit
      notamment du prénom et du nom, de l'âge, de l'adresse postale, l'adresse mail, la localisation de l'utilisateur
      ou encore son adresse IP ;
    </li>
    <li>
      Quels sont les droits des utilisateurs concernant ces données ;
    </li>
    <li>
      Qui est responsable du traitement des données à caractère personnel collectées et traitées ;
    </li>
    <li>
      A qui ces données sont transmises ;
    </li>
    <li>
      Eventuellement, la politique du site en matière de fichiers "cookies".
    </li>
  </ul>
  <p>
    Cette politique de confidentialité complète les mentions légales et les Conditions Générales d'Utilisation que les
    utilisateurs peuvent consulter à l'adresse ci-après :<br>
    {{Constants.APP_URL}}fr/terms-of-service
  </p>

  <h2>Article 2 : PRINCIPES GÉNÉRAUX EN MATIÈRE DE COLLECTE ET DE TRAITEMENT DE DONNÉES</h2>
  <p>
    Conformément aux dispositions de l'article 5 du Règlement européen 2016/679, la collecte et le traitement des
    données des utilisateurs du site respectent les principes suivants :
  </p>
  <ul>
    <li>
      Licéité, loyauté et transparence : les données ne peuvent être collectées et traitées qu'avec le consentement de
      l'utilisateur propriétaire des données. A chaque fois que des données à caractère personnel seront collectées, il
      sera indiqué à l'utilisateur que ses données sont collectées, et pour quelles raisons ses données sont collectées ;
    </li>
    <li>
      Finalités limitées : la collecte et le traitement des données sont exécutés pour répondre à un ou plusieurs
      objectifs déterminés dans les présentes conditions générales d'utilisation ;
    </li>
    <li>
      Minimisation de la collecte et du traitement des données : seules les données nécessaires à la bonne exécution
      des objectifs poursuivis par le site sont collectées ;
    </li>
    <li>
      Conservation des données réduites dans le temps : les données sont conservées pour une durée limitée, dont
      l'utilisateur est informé. Lorsque cette information ne peut pas être communiquée, l'utilisateur est informé des
      critères utilisés pour déterminer la durée de conservation ;
    </li>
    <li>
      Intégrité et confidentialité des données collectées et traitées : le responsable du traitement des données
      s'engage à garantir l'intégrité et la confidentialité des données collectées.
    </li>
  </ul>
  <p>
    Afin d'être licites, et ce conformément aux exigences de l'article 6 du règlement européen 2016/679, la collecte et
    le traitement des données à caractère personnel ne pourront intervenir que s'ils respectent au moins l'une des
    conditions ci-après énumérées :
  </p>
  <ul>
    <li>
      L'utilisateur a expressément consenti au traitement ;
    </li>
    <li>
      Le traitement est nécessaire à la bonne exécution d'un contrat ;
    </li>
    <li>
      Le traitement répond à une obligation légale ;
    </li>
    <li>
      Le traitement s'explique par une nécessité liée à la sauvegarde des intérêts vitaux de la personne concernée ou
      d'une autre personne physique ;
    </li>
    <li>
      Le traitement peut s'expliquer par une nécessité liée à l'exécution d'une mission d'intérêt public ou qui relève
      de l'exercice de l'autorité publique ;
    </li>
    <li>
      Le traitement et la collecte des données à caractère personnel sont nécessaires aux fins des intérêts légitimes
      et privés poursuivis par le responsable du traitement ou par un tiers.
    </li>
  </ul>

  <h2>Article 3 : DONNÉES À CARACTÈRE PERSONNEL COLLECTÉES ET TRAITÉES DANS LE CADRE DE LA NAVIGATION SUR LE SITE</h2>
  <h3>A. DONNÉES COLLECTÉES ET TRAITÉES ET MODE DE COLLECTE</h3>
  <p>
    Les données à caractère personnel collectées sur le site NooS Global sont les suivantes :
  </p>
  <ul>
    <li>
      Nom
    </li>
    <li>
      Prénom
    </li>
    <li>
      Email
    </li>
    <li>
      Pays de résidence fiscale
    </li>
    <li>
      Projets socio-environnementaux soutenus (opinions philosophiques ?)
    </li>
    <li>
      Causes soutenues (en lien avec les projets soutenus e.g. reforestation, etc.)
    </li>
    <li>
      Zones géographiques préférées (où générer de l’impact)
    </li>
    <li>
      Si employé, nom de la société
    </li>
    <li>
      Langue préférée (En, Es ou Fr)
    </li>
    <li>
      Date de création de compte
    </li>
    <li>
      Date du dernier login ou de la dernière action
    </li>
  </ul>
  <p>
    Ces données sont collectées lorsque l'utilisateur effectue l'une des opérations suivantes sur le site :
  </p>
  <ul>
    <li>
      Lorsqu'un utilisateur crée un compte sur NooS
    </li>
    <li>
      Lorsqu'un utilisateur fait un don
    </li>
    <li>
      Lorsqu'un utilisateur remplit le formulaire de contact
    </li>
    <li>
      Lorsqu'un utilisateur participe à une opération de don menée par un client de NooS
    </li>
  </ul>
  <p>
    Par ailleurs, lors d'un paiement sur le site, il sera conservé dans les systèmes informatiques de l'éditeur du
    site une preuve de la transaction comprenant le bon de commande et la facture.<br>
    Le responsable du traitement conservera dans ses systèmes informatiques du site et dans des conditions
    raisonnables de sécurité l'ensemble des données collectées pour une durée de :
  </p>
  <ul>
    <li>
      Données comptables : 10 ans
    </li>
    <li>
      Documents contractuels et commerciaux : 3 ans
    </li>
    <li>
      Cookie d’analyse des visites : 26 mois.
    </li>
  </ul>
  <p>
    La collecte et le traitement des données répondent aux finalités suivantes :
  </p>
  <ul>
    <li>
      Prédiction et calcul de l’impact de l’utilisateur
    </li>
    <li>
      Communication de l’impact par email
    </li>
    <li>
      Amélioration de l’engagement de l’utilisateur
    </li>
    <li>
      Réponse aux demandes envoyées par le biais du formulaire de contact
    </li>
  </ul>
  <p>
    Les traitements de données effectués sont fondés sur les bases légales suivantes :
  </p>
  <ul>
    <li>
      Consentement de l’utilisateur
    </li>
    <li>
      Exécution du contrat
    </li>
  </ul>

  <h3>B. TRANSMISSION DES DONNÉES A DES TIERS</h3>
  <p>
    Les données peuvent être transmises au(x) tiers ci-après énuméré(s) :
  </p>
  <ul>
    <li>
      Google Analytics pour l’analyse du traffic
    </li>
    <li>
      DigitalOcean pour l’hébergement des serveurs
    </li>
    <li>
      Sendgrid pour l’envoi automatique d’emails
    </li>
    <li>
      Mailchimp pour l’envoi d’emails marketing
    </li>
    <li>
      Stripe pour les paiements en ligne
    </li>
  </ul>

  <h3>C. HÉBERGEMENT DES DONNÉES</h3>
  <p>
    Le site NooS Global est hébergé par : Digitalocean LLC, dont le siège est situé à l'adresse ci-après :<br>
    101 Avenue of the Americas 10th Floor New York, NY 10013 United States<br>
    L'hébergeur peut être contacté au numéro de téléphone suivant : +1-347-903-7918.<br>
    Les données collectées et traitées par le site restent au sein de l’Union européenne. NooS utilise DigitalOcean,
    un fournisseur de confiance reconnu pour ses mesures de protection des données, en totale conformité avec les
    principes du RGPD.
  </p>

  <h2>ARTICLE 4  : RESPONSABLE DU TRAITEMENT DES DONNÉES ET DÉLÉGUÉ À LA PROTECTION DES DONNÉES</h2>
  <h3>A. LE RESPONSABLE DU TRAITEMENT DES DONNÉES</h3>
  <p>
    Le responsable du traitement des données à caractère personnel est : Sergio López Sanz. Il peut être contacté de la manière suivante :<br>
    Par email : sergio&#64;noos.global<br>
    Le responsable du traitement des données est chargé de déterminer les finalités et les moyens mis au service du
    traitement des données à caractère personnel.
  </p>

  <h3>B. OBLIGATIONS DU RESPONSABLE DU TRAITEMENT DES DONNÉES</h3>
  <p>
    Le responsable du traitement s'engage à protéger les données à caractère personnel collectées, à ne pas les
    transmettre à des tiers sans que l'utilisateur n'en ait été informé et à respecter les finalités pour lesquelles
    ces données ont été collectées.<br>
    Le site dispose d'un certificat SSL afin de garantir que les informations et le transfert des données transitant par le site sont sécurisés.<br>
    Un certificat SSL ("Secure Socket Layer" Certificate) a pour but de sécuriser les données échangées entre l'utilisateur et le site.<br>
    De plus, le responsable du traitement des données s'engage à notifier l'utilisateur en cas de rectification ou de
    suppression des données, à moins que cela n'entraîne pour lui des formalités, coûts et démarches disproportionnés.<br>
    Dans le cas où l'intégrité, la confidentialité ou la sécurité des données à caractère personnel de l'utilisateur
    est compromise, le responsable du traitement s'engage à informer l'utilisateur par tout moyen.
  </p>

  <h3>C. LE DÉLÉGUÉ À LA PROTECTION DES DONNÉES</h3>
  <p>
    Par ailleurs, l'utilisateur est informé qu'un Délégué à la Protection des Données a été nommé : Sergio López Sanz.<br>
    Le rôle du Délégué à la Protection des Données et de s'assurer la bonne mise en œuvre des dispositions nationales
    et supranationales relatives à la collecte et au traitement des données à caractère personnel. Il peut également
    être nommé DPO (pour Data Protection Officer).<br>
    Le délégué à la protection des données peut être joint de la manière suivante :<br>
    Par email : sergio&#64;noos.global
  </p>

  <h2>ARTICLE 5  : DROITS DE L'UTILISATEUR</h2>
  <p>
    Conformément à la réglementation concernant le traitement des données à caractère personnel, l'utilisateur possède
    les droits ci-après énumérés.<br>
    Afin que le responsable du traitement des données fasse droit à sa demande, l'utilisateur est tenu de lui
    communiquer : ses prénom et nom ainsi que son adresse e-mail, et si cela est pertinent, son numéro de compte ou
    d'espace personnel ou d'abonné.<br>
    Le responsable du traitement des données est tenu de répondre à l'utilisateur dans un délai de 30 (trente) jours maximum.
  </p>

  <h3>A. PRÉSENTATION DES DROITS DE L'UTILISATEUR EN MATIÈRE DE COLLECTE ET TRAITEMENT DE DONNÉES</h3>
  <h4>a. Droit d'accès, de rectification et droit à l'effacement</h4>
  <p>
    L'utilisateur peut prendre connaissance, mettre à jour, modifier ou demander la suppression des données le
    concernant, en respectant la procédure ci-après énoncée :<br>
    Un email doit être envoyé à we-comply-with-RGPD&#64;noos.global en précisant la sollicitation.<br>
    S'il en possède un, l'utilisateur a le droit de demander la suppression de son espace personnel en suivant la procédure suivante :<br>
    Un email doit être envoyé à we-comply-with-RGPD&#64;noos.global en précisant la sollicitation.
  </p>

  <h4>b. Droit à la portabilité des données</h4>
  <p>
    L'utilisateur a le droit de demander la portabilité de ses données personnelles, détenues par le site, vers un
    autre site, en se conformant à la procédure ci-après :<br>
    Un email doit être envoyé à we-comply-with-RGPD&#64;noos.global en précisant la sollicitation.
  </p>

  <h4>c. Droit à la limitation et à l'opposition du traitement des données</h4>
  <p>
    L'utilisateur a le droit de demander la limitation ou de s'opposer au traitement de ses données par le site, sans
    que le site ne puisse refuser, sauf à démontrer l'existence de motifs légitimes et impérieux, pouvant prévaloir
    sur les intérêts et les droits et libertés de l'utilisateur.<br>
    Afin de demander la limitation du traitement de ses données ou de formuler une opposition au traitement de ses
    données, l'utilisateur doit suivre la procédure suivante :<br>
    Un email doit être envoyé à we-comply-with-RGPD&#64;noos.global en précisant la sollicitation.
  </p>

  <h4>d. Droit de ne pas faire l'objet d'une décision fondée exclusivement sur un procédé automatisé</h4>
  <p>
    Conformément aux dispositions du règlement 2016/679, l'utilisateur a le droit de ne pas faire l'objet d'une
    décision fondée exclusivement sur un procédé automatisé si la décision produit des effets juridiques le
    concernant, ou l'affecte de manière significative de façon similaire.
  </p>

  <h4>e. Droit de déterminer le sort des données après la mort</h4>
  <p>
    Il est rappelé à l'utilisateur qu'il peut organiser quel doit être le devenir de ses données collectées et
    traitées s'il décède, conformément à la loi n°2016-1321 du 7 octobre 2016.
  </p>

  <h4>f. Droit de saisir l'autorité de contrôle compétente</h4>
  <p>
    Dans le cas où le responsable du traitement des données décide de ne pas répondre à la demande de l'utilisateur,
    et que l'utilisateur souhaite contester cette décision, ou, s'il pense qu'il est porté atteinte à l'un des droits
    énumérés ci-dessus, il est en droit de saisir la CNIL (Commission Nationale de l'Informatique et des Libertés,
    https://www.cnil.fr) ou tout juge compétent.
  </p>

  <h3>B. DONNÉES PERSONNELLES DES PERSONNES MINEURES</h3>
  <p>
    Conformément aux dispositions de l'article 8 du règlement européen 2016/679 et à la loi Informatique et Libertés,
    seuls les mineurs âgés de 15 ans ou plus peuvent consentir au traitement de leurs données personnelles.<br>
    Si l'utilisateur est un mineur de moins de 15 ans, l'accord d'un représentant légal sera requis afin que des
    données à caractère personnel puissent être collectées et traitées.<br>
    L'éditeur du site se réserve le droit de vérifier par tout moyen que l'utilisateur est âgé de plus de 15 ans, ou
    qu'il aura obtenu l'accord d'un représentant légal avant de naviguer sur le site.
  </p>

  <h2>ARTICLE 6  : UTILISATION DES FICHIERS "COOKIES"</h2>
  <p>
    Le site a éventuellement recours aux techniques de "cookies".<br>
    Un "cookie" est un fichier de petite taille (moins de 4 ko), stocké par le site sur le disque dur de l'utilisateur,
    contenant des informations relatives aux habitudes de navigation de l'utilisateur.<br>
    Ces fichiers lui permettent de traiter des statistiques et des informations sur le trafic, de faciliter la
    navigation et d'améliorer le service pour le confort de l'utilisateur.<br>
    Pour l'utilisation de fichiers "cookies" impliquant la sauvegarde et l'analyse de données à caractère personnel,
    le consentement de l'utilisateur est nécessairement demandé.<br>
    Ce consentement de l'utilisateur est considéré comme valide pour une durée de 6 (six) mois maximum. A l'issue de
    cette période, le site demandera à nouveau l'autorisation de l'utilisateur pour enregistrer des fichiers "cookies" sur son disque dur.
  </p>

  <h4>a. Opposition de l'utilisateur à l'utilisation de fichiers "cookies" par le site</h4>
  <p>
    Les cookies non essentiels au fonctionnement du site ne sont déposés sur le terminal de l'utilisateur qu'après
    avoir obtenu son consentement. L'utilisateur peut retirer son consentement à tout moment, de la manière suivante :<br>
    NooS utilise le logiciel Axeptio pour la gestion des cookies. Via ce logiciel, il est proposé à l'utilisateur de
    retirer son consentement à tout moment.<br>
    De manière plus générale, il est porté à la connaissance de l'utilisateur qu'il peut s'opposer à l'enregistrement
    de ces fichiers "cookies" en configurant son logiciel de navigation.<br>
    Pour information, l'utilisateur peut trouver aux adresses suivantes les démarches à suivre afin de configurer son
    logiciel de navigation pour s'opposer à l'enregistrement des fichiers "cookies" :
  </p>
  <ul>
    <li>
      <b>Chrome</b> : https://support.google.com/accounts/answer/61416?hl=fr
    </li>
    <li>
      <b>Firefox</b> : https://support.mozilla.org/fr/kb/enable-and-disable-cookies-website-preferences
    </li>
    <li>
      <b>Safari</b> : http://www.apple.com/legal/privacy/fr-ww/
    </li>
    <li>
      <b>Internet Explorer</b> : https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies
    </li>
    <li>
      <b>Opera</b> : http://www.opera.com/help/tutorials/security/cookies/
    </li>
  </ul>
  <p>
    Dans le cas où l'utilisateur décide de désactiver les fichiers "cookies", il pourra poursuivre sa navigation sur
    le site. Toutefois, tout dysfonctionnement du site provoqué par cette manipulation ne pourrait être considéré comme
    étant du fait de l'éditeur du site.
  </p>

  <h2>ARTICLE 7  : CONDITIONS DE MODIFICATION DE LA POLITIQUE DE CONFIDENTIALITÉ</h2>
  <p>
    La présente politique de confidentialité peut être consultée à tout moment à l'adresse ci-après indiquée :<br>
    {{Constants.APP_URL}}fr/RGPD<br>
    L'éditeur du site se réserve le droit de la modifier afin de garantir sa conformité avec le droit en vigueur.<br>
    Par conséquent, l'utilisateur est invité à venir consulter régulièrement cette politique de confidentialité afin
    de se tenir informé des derniers changements qui lui seront apportés.<br>
    Il est porté à la connaissance de l'utilisateur que la dernière mise à jour de la présente politique de
    confidentialité est intervenue le : 01/04/2024.
  </p>

  <h2>ARTICLE 8  : ACCEPTATION PAR L'UTILISATEUR DE LA POLITIQUE DE CONFIDENTIALITÉ</h2>
  <p>
    En naviguant sur le site, l'utilisateur atteste avoir lu et compris la présente politique de confidentialité et
    en accepte les conditions, en ce qui concerne plus particulièrement la collecte et le traitement de ses données
    à caractère personnel, ainsi que l'utilisation de fichiers "cookies".
  </p>
</div>
